export function filters() {
    var filterColors = new Set();
    var filterStyles = new Set();
    $('.js-filter').click(function (evt) {
        evt.preventDefault();
        let target = $(this).data('slug');

        // Clear filter, show everything
        if ($(this).hasClass('js-clear')) {
            resetFilters();
        } else {
            // 1st filter activated
            if ($('.js-filter.active').length == 0) {
                hideAllProducts();
                $(this).addClass('active');
                showProduct(target)

            } else {
                // more than 1 filters active
                if (!$(this).hasClass('active')) {
                    $(this).addClass('active');

                    showProduct(target);

                } else {

                    // Filter Deactivation
                    $(this).removeClass('active');
                    if ($('.js-filter.active').length > 0) {
                        // only 1 of the filters deactivated
                        hideProduct(target);
                    } else {

                        // All filters deactivated
                        resetFilters();
                    }
                }
            }
        }

        // Reset product count
        $('.js-product-count').text($('.product-card.active').length);

    })

    // Mobile Filters
    $('.js-filters-trigger').click(function() {
        $('form#filterForm').toggleClass('active');
    })

    // Clear Filters
    $('.js-filter-clear').click(function() {
        resetFilters();
    })

    // $(window).on('resize', function () {
    //     if ($(window).width() < 768) {
    //         // Reset filters
    //         resetFilters()
    //     }
    // })

    function resetFilters() {
        filterColors.clear();
        filterStyles.clear();

        $('.js-filter').removeClass('active')
        $('.product-card').fadeIn(700);
        $('.product-card').addClass('active');
        $('.js-product-count').text($('.product-card.active').length);
    }
    function hideAllProducts() {
        // filterColors.clear();
        // filterStyles.clear();

        $('.product-card').fadeOut(500);
        $('.product-card').removeClass('active');
    }

    function showProduct(target) {
        if (target.startsWith('color')) {
            filterColors.add(target);
        }

        if (target.startsWith('style')) {
            filterStyles.add(target);
        }

        processProducts();

        // $('.product-card.' + target).fadeIn(700);
        // $('.product-card.' + target).addClass('active');
    }
    function hideProduct(target) {
        if (target.startsWith('color')) {
            filterColors.delete(target);
        }

        if (target.startsWith('style')) {
            filterStyles.delete(target);
        }

        processProducts();

        // $('.product-card.' + target).fadeOut(500);
        // $('.product-card.' + target).removeClass('active');
    }

    function processProducts() {
        
        let res = $('.product-card');
        // console.info(res);
        if (filterStyles.size) {
            let styles = '.' + Array.from(filterStyles).join(',.');
            res = res.filter(function(idx, itm){
                return $(itm).is(styles);
            });
        }
        
        if (filterColors.size) {
            let colors = '.' + Array.from(filterColors).join(',.');
            res = res.filter(function(idx, itm){
                return $(itm).is(colors);
            });
        }
        
        hideAllProducts();
        $(res).each(function (idx, itm) {
            $(itm).fadeIn(700);
            $(itm).addClass('active');
        });
    }
}